exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-docs-index-js": () => import("./../../../src/pages/docs/index.js" /* webpackChunkName: "component---src-pages-docs-index-js" */),
  "component---src-pages-english-privacy-index-js": () => import("./../../../src/pages/english-privacy/index.js" /* webpackChunkName: "component---src-pages-english-privacy-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-index-js": () => import("./../../../src/pages/privacy-policy/index.js" /* webpackChunkName: "component---src-pages-privacy-policy-index-js" */),
  "component---src-pages-service-intro-index-jsx": () => import("./../../../src/pages/serviceIntro/index.jsx" /* webpackChunkName: "component---src-pages-service-intro-index-jsx" */),
  "component---src-pages-service-order-index-js": () => import("./../../../src/pages/service-order/index.js" /* webpackChunkName: "component---src-pages-service-order-index-js" */)
}

